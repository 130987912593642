<template>
  <div data-cy="checkout-cart-summary" class="CheckoutCartSummary pt-5 px-8">
    <i18n-t
      tag="p"
      keypath="productsCount"
      class="mt-2 mx-auto text-lg-1 font-display font-bold text-primary-A900 flex items-center justify-between"
    >
      <template #count>
        <span class="text-secondary-700 font-normal font-body">
          {{ $t('products', { count }) }}
        </span>
      </template>
    </i18n-t>
    <ul class="CheckoutCartSummary__List mt-6 divide-y-1">
      <li
        v-for="{ id, quantity, name, unitPrice, image, stock, url } in items"
        :key="id"
        class="flex items-stretch gap-x-6"
      >
        <AppImage :src="image?.src ?? ''" :alt="image?.alt ?? ''" class="CartItem__image" width="100" height="100" />

        <div class="flex flex-1 flex-col justify-between">
          <AppLink :href="url" class="CartItem__name text-primary-A900 font-body text-base">
            {{ name }}
          </AppLink>

          <div class="w-full flex items-center justify-between">
            <span class="text-secondary-700">{{ $t('qty', { quantity }) }}</span>
            <Money :value="unitPrice ?? 0 * quantity" class="text-primary-700" />
          </div>
          <span v-if="!stock" class="block mt-1 text-sm text-red-700">{{ $t('outOfStockLabel') }}</span>
          <span v-if="stock && stock < quantity" class="block mt-1 text-sm text-primary-500">{{
            $t('qtyUnavailableLabel')
          }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '@robustastudio/e-commerce/common';

defineComponent({
  name: 'CartSummary',
});

const { t: $t } = useI18n({ useScope: 'local' });

defineProps({
  items: {
    type: Array as PropType<CartItem[] | []>,
    default: () => [],
  },
  count: {
    type: Number,
    default: 0,
  },
});
</script>

<i18n>
{
  "en": {
    "products": "{count} Products",
    "qty": "Qty . {quantity}",
    "purchaseItems": "Purchase Items",
    "productsCount": "Products {count}",
    "customMessage": "CUSTOM MESSAGE",
    "outOfStockLabel": "Out of stock",
    "qtyUnavailableLabel": "Quantity unavailable"
  },
  "ar": {
    "products": "المنتجات",
    "purchaseItems": "المنتجات المشتراه",
    "productsCount": "لديك {count} منتج فى طلبك ",
    "qty": "الكمية {count}",
    "customMessage": "رسالة مخصصة",
    "outOfStockLabel": "غير متوفر",
    "qtyUnavailableLabel": "الكمية غير متوفرة"
  }
}
</i18n>

<style lang="postcss" scoped>
.CheckoutCartSummary {
  @apply bg-white rounded-5xs;
  filter: drop-shadow(0px 0px 48px rgba(21, 44, 115, 0.15));

  @apply pb-16;

  &__List {
    & li:not(:first-child) {
      @apply pt-6;
    }

    & li:not(:last-child) {
      @apply pb-6;
    }
  }
}

@screen lg {
  .CheckoutCartSummary {
    @apply py-8;
    &::before {
      content: unset;
    }
  }
}

.CartItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  &__image {
    @apply bg-white;
    width: 100px;
    height: 100px;

    img {
      @apply object-contain w-full h-full;
    }
  }

  &__name {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@screen md {
  .CartItem {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}
.oneline {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
